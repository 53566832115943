jQuery(document).ready(function( $ ) {
	
	$('.home-slider').bxSlider({
	  mode: 'fade',
	  controls: false,
	  adaptiveHeight: true
	});

	$('.bxslider').bxSlider({
		pager: false
	});
	//FORM FOCUS

	$(".wpcf7 [type=text],.wpcf7 [type=email]").focus(function(){
		$(this).parent().prev().addClass("active");
	}).blur(function(){
		$(this).parent().prev().removeClass("active");
	});

	// MOBILE NAVIGATION TOGGLE **************
	$( ".mobile-nav" ).click(function() {
		$( this ).toggleClass( "open" );
		$( ".mobile-menu--wrapper" ).slideToggle();
	});

	// MOBILE SUB-NAVIGATION TOGGLE **************
	$( ".menu li.menu-item-has-children > a" ).click(function(e) {
		e.preventDefault();
	});

	$( ".mobile-menu--wrapper li.menu-item-has-children > a" ).click(function(e) {
		e.preventDefault();
		$( this ).toggleClass( "open" );
		$( this ).parent().children('.menu').slideToggle();
	});

	var initialPos =  $('.wrap-header').offset().top;
	// STICKY NAV *******************
	$(window).on('scroll', function(){
		var top = $(window).scrollTop();
	    if (top > initialPos) {
	        $('.wrap-header').addClass('sticky');
	        $('body').addClass('sticky-nav-on');
	    }
	    else {
	        $('.wrap-header').removeClass('sticky');
	        $('body').removeClass('sticky-nav-on');
	    }
	});

    var $window = $(window);
    var window_width = $window.width();
    var window_height = $window.height();

    var $document = $(document);

    var $body = $('body');
    var body_height = $body.outerHeight();

    var $header = $('.wrap-header');
    var header_height = $header.outerHeight();

    var $footer = $('.footer');
    var footer_height = $footer.outerHeight();

    $window.resize(function() {
        window_width = $window.width();
        window_height = $window.height();
        header_height = $header.outerHeight();
    });


    // PROCESS HASH LINKS *******************
    $body.on('click', 'a[href*=#]', function(e) {
        var url = $(this).attr('href');
        var hash = url.substring(url.indexOf('#'));
        if ($(hash).length) {
            e.preventDefault();
            process_hash(hash);
        }
    });

    function process_hash( hash ) {

		$( ".mobile-nav" ).removeClass( "open" );
		$( ".mobile-menu--wrapper" ).slideUp();

        var element;
        var hash_value = hash.substr(1);

        element = $( hash );

        if(element.hasClass('accordion-content') || element.hasClass('description')) {
        	setTimeout(function(){
		        $('html,body').animate({scrollTop: element.offset().top - 250}, 1000);
		    }, 300); //Adjust to match slideSpeed
        } else {
        	console.log('should not');
        	if ( element instanceof jQuery ) {

	            var offset = element.offset().top - header_height;

	            $('html, body').animate({
	                scrollTop: offset + 1
	            }, 1000);
	        }
        }        
    }
    $window.on("load", function() {
        if ( window.location.hash ) {
            process_hash( window.location.hash );
        }
    });

	// FadeIn Element on scroll
    $(window).scroll( function(){
    
        $('.timeline .row').each( function(e){
            
            var middle_of_object = $(this).offset().top + ( $(this).outerHeight() / 2 );
            var bottom_of_object = $(this).offset().top + $(this).outerHeight();
            var bottom_of_window = $(window).scrollTop() + $(window).height();
            
            if( bottom_of_window > middle_of_object ){
                
                $(this).animate({'opacity':'1'}, 250);
                    
            }

			if( bottom_of_window > bottom_of_object ){
                
                $(this).children('.grid-container').animate({'opacity':'1'}, 500);
                    
            }                
            
        }); 
    
    });

    $(".fancybox-video").fancybox({
		maxWidth	: 1100,
		maxHeight	: 600,
		fitToView	: false,
		width		: '90%',
		height		: '90%',
		autoSize	: false,
		closeClick	: false,
		openEffect	: 'fade',
		closeEffect	: 'fade'
	});

	$('.logos').slick({
		dots: false,
		infinite: true,
		speed: 500,
	    autoplay: true,
	    slidesToShow: 4,
	    slidesToScroll: 1,
		draggable: false,
		arrows: false,
		responsive: [
		  {
		    breakpoint: 1024,
		    settings: {
		      slidesToShow: 3,
		      slidesToScroll: 1,
		    }
		  },
		  {
		    breakpoint: 770,
		    settings: {
		      slidesToShow: 2,
		      slidesToScroll: 1
		    }
		  },
		  {
		    breakpoint: 480,
		    settings: {
		      slidesToShow: 1,
		      slidesToScroll: 1
		    }
		  }
		]
	});

});
